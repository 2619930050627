import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import { Activity, Course } from '@kritik/types.generated';
import { isGradingOrLater } from '@kritik/utils/stage';
import { AdvancedOptionField } from 'components/ActivityEdit/AdvancedOptions/Field';
import AttachmentType from 'components/ActivityEdit/AdvancedOptions/Fields/AttachmentType';
import NumEvalsToAssign from 'components/ActivityEdit/AdvancedOptions/Fields/NumEvalsToAssign';
import SelfEvaluation from 'components/ActivityEdit/AdvancedOptions/Fields/SelfEvaluation';
import StartingScoreField from 'components/ActivityEdit/AdvancedOptions/Fields/StartingScore';
import TopicsField from 'components/ActivityEdit/AdvancedOptions/Fields/Topics';
import WeightField from 'components/ActivityEdit/AdvancedOptions/Fields/Weight';
import MinimumWordCount from 'components/Course/Edit/CourseDetails/MinimumWordCount';
import { SimilarityReportSetting } from 'components/Course/Edit/CourseDetails/SimilarityReportSetting';
import { LateSubmissionSettings } from 'components/DefaultActivitySettings/LateSubmissionSettings';
import { TranslatedText } from 'components/TranslatedText';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import { useFetchActivity } from 'hooks/activity';
import { localize } from 'locales';
import React from 'react';
import { useParams } from 'react-router';
import MissedCreationOption from './Fields/MissedCreation';
import OptionalCreateStage from './Fields/OptionalCreateStage';

type Props = {
  form?: any;
  values: any;
  course: Course;
  activity: Activity;
};

const AdvancedOptions = (props: Props) => {
  const { assignmentId } = useParams();
  const { data: activity } = useFetchActivity(assignmentId);
  const isSimilarityReportEnabled = useIsFeatureFlagEnabled('similarity_report');

  const renderTopics =
    props.course &&
    (props.values.activityType === ACTIVITY_TYPES.PEER || props.values.activityType === ACTIVITY_TYPES.GROUP);

  return (
    <React.Fragment>
      <h3>
        <TranslatedText i18nKey="AdvancedOptions.Options" />
      </h3>
      {renderTopics && <TopicsField activity={props.activity} />}
      <AdvancedOptionField>
        <AttachmentType />
      </AdvancedOptionField>
      <h3>{localize({ message: 'ActivitySettings.Default' })}</h3>
      <MinimumWordCount
        testid="word-count-activity"
        fieldName="settings.minimumWordCountForEvaluations"
        wordCount={props.course.courseSettings?.minimumWordCountForEvaluations}
      />
      <AdvancedOptionField>
        <NumEvalsToAssign activity={activity} values={props.values} />
      </AdvancedOptionField>
      {props.values.activityType === ACTIVITY_TYPES.WITHIN_GROUP && (
        <AdvancedOptionField>
          <SelfEvaluation settings={props.values.settings} />
        </AdvancedOptionField>
      )}
      <WeightField />
      {props.course.markingScheme.isPercentage && (
        <StartingScoreField isDuplicatingActivity={props.values?.settings?.isDuplicating} />
      )}
      <MissedCreationOption values={props.values} />
      {props.values.activityType === ACTIVITY_TYPES.WITHIN_GROUP && (
        <OptionalCreateStage
          isCreateStageOptional={props.values.isCreateStageOptional}
          onOptionalCreateStageChange={(e) => {
            props.form.change('isCreateStageOptional', e.target.checked);
          }}
          activity={activity}
          isDuplicatingActivity={props.values?.settings?.isDuplicating}
          acceptLateCreations={props.values.acceptLateSubmissions}
        />
      )}
      <LateSubmissionSettings
        showNoLateSubmissionsAllowedNotice={props.values.removeStudentFromActivityAfterMissedCreation === true}
        acceptLateCreations={props.values.acceptLateSubmissions}
        onAcceptLateCreationsChange={(e) => {
          props.form.change('acceptLateSubmissions', e.target.checked);
          props.form.change('autoAccept', false);
        }}
        autoAcceptLateCreations={props.values.autoAccept}
        onAutoAcceptLateCreationsChange={(e) => props.form.change('autoAccept', e.target.checked)}
        acceptLateEvaluations={props.values.acceptLateEvaluations}
        onAcceptLateEvaluationsChange={(e) => {
          props.form.change('acceptLateEvaluations', e.target.checked);
          props.form.change('autoAcceptLateEvaluations', false);
        }}
        autoAcceptLateEvaluations={props.values.autoAcceptLateEvaluations}
        onAutoAcceptLateEvaluationsChange={(e) => props.form.change('autoAcceptLateEvaluations', e.target.checked)}
        activity={activity}
        isDuplicatingActivity={props.values?.settings?.isDuplicating}
      />
      {isSimilarityReportEnabled && (
        <SimilarityReportSetting
          enableSimilarityReport={props.values.enableSimilarityReport}
          disabled={isGradingOrLater(props.activity)}
        />
      )}
    </React.Fragment>
  );
};

export default AdvancedOptions;
